import { useEffect, useState } from 'react';
import Inko from "inko";

const useDebounce = (value: string, delay: number): any => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    const [searchTranslated, setSearchTranslated] = useState(["", ""]);
    const inko = new Inko();

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedValue(value);
            const engToKor = inko.en2ko(value);
            const korToEng = inko.ko2en(value);
            setSearchTranslated([engToKor, korToEng]);
        }, delay);

        return () => {
            clearTimeout(timer);
        }; //value 변경 시점에 clearTimeout을 해줘야함.
    }, [value]);

    return [debouncedValue, searchTranslated];
};

export default useDebounce;


