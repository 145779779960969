import { useCallback } from 'react';

const useHandleImageError = () => {
    const handleImageError = useCallback(
        (event: React.SyntheticEvent<HTMLImageElement>, exchange: string) => {
            const target = event.target as HTMLImageElement;

            if (exchange === "KOSPI") {
                // target.src = '/images/logos/KOSPI.svg';
                target.src = 'https://riskweather-public.s3.ap-northeast-2.amazonaws.com/logos/KOSPI.svg';
            } else if (exchange === "KOSDAQ") {
                // target.src = '/images/logos/KOSDAQ.svg';
                target.src = 'https://riskweather-public.s3.ap-northeast-2.amazonaws.com/logos/KOSDAQ.svg';

            } else {
                // target.src = '/images/logos/errorLogo.png';
                target.src = "https://riskweather-public.s3.ap-northeast-2.amazonaws.com/logos/errorLogo.png";
            }
        },
        []
    );

    return handleImageError;
};


export default useHandleImageError