import useSWR from "swr";
import { fetcher } from "./fetcher";

const useSearchList = ({ search, search2, searchOrigin }: { search: string, search2?: string, searchOrigin?: string }): [any[], boolean] => {
    const { data: explodeDt, isValidating } = useSWR(
        `/api/search?search=${encodeURIComponent(search)}&search2=${search2}&risk=true&searchOrigin=${searchOrigin}`,
        fetcher,
        {
            revalidateOnMount: true,
            revalidateOnFocus: false,
        }
    );

    // 초기 로딩 상태
    const isValid = !explodeDt && isValidating;


    const data = explodeDt
        ? search.length === 0
            ? [].concat(...explodeDt).slice(0, 30)
            : [].concat(...explodeDt)
        : [];
    return [data, isValid];
}

export const usePortSearch = ({ search, search2, isTrending = false, searchOrigin }: { search: string, search2?: string, searchOrigin?: string, isTrending?: boolean }): [any[], boolean] => {
    const { data: explodeDt, isValidating } = useSWR(
        search
            ? `/api/search?search=${encodeURIComponent(search)}&search2=${search2}&price=true&trending=${isTrending}&searchOrigin=${searchOrigin}`
            : null,
        fetcher,
        {
            revalidateOnMount: true,
            revalidateOnFocus: false,
        }
    );

    // 초기 로딩 상태
    const isValid = !explodeDt && isValidating;

    const data = explodeDt ? [].concat(...explodeDt) : [];
    return [data, isValid];
}
export const usePortTrending = ({ isTrending = false }: { isTrending?: boolean }): [any[], boolean] => {
    const { data: explodeDt, isValidating } = useSWR(
        `/api/search?price=true&trending=${isTrending}`,
        fetcher,
        {
            revalidateOnMount: true,
            revalidateOnFocus: false,
        }
    );

    // 초기 로딩 상태
    const isValid = !explodeDt && isValidating;

    const data = explodeDt ? [].concat(...explodeDt) : [];
    return [data, isValid];
}


export default useSearchList;