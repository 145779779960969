import { useRouter } from "next/router";
import { TYPO } from "styles/Typo";

function EmptySearchItem() {
  const router = useRouter();
  return (
    <div className="mt-4 px-6 flex flex-col gap-[10px] pb-6">
      <span className={`${TYPO.TYPO5_1_SB} text-gray80`}>
        {router.locale === "ko"
          ? "검색 결과가 없어요"
          : "No search results found"}
      </span>
      <ul className="flex flex-col list-disc list-inside pl-1">
        <li className={`${TYPO.TYPO7_1_RG} text-gray40`}>
          {router.locale === "ko"
            ? "단어의 철자가 정확한지 확인해 보세요."
            : "Check the spelling of the word."}
        </li>
        <li className={`${TYPO.TYPO7_1_RG} text-gray40`}>
          {router.locale === "ko"
            ? "3년 이내에 상장된 종목은 검색되지 않을 수 있어요"
            : "Items listed within the last 3 years may not be searched."}
        </li>
      </ul>
    </div>
  );
}

export default EmptySearchItem;
